<template>
    <div class="userInfoBox">
        <van-nav-bar title="个人信息" safe-area-inset-top   placeholder fixed  left-text="返回" left-arrow @click-left="$router.push('/home')" >
           <template slot="right">
                <span class="CloseBox" @click="Colsed">
                    <van-icon  class-prefix="iconfont" name="exit" size="18" />
                    <em>退出</em>
                </span>
           </template>
        </van-nav-bar> 
        <div class="FormBox">
            <van-form  @submit="onSubmit" ref="form" :show-error='false'>
                <van-cell-group>
                    <van-field required  label="用户头像" :rules="[{ required: true, message: '用户头像' }]">
                        <template #input>
                            <van-uploader v-model="uploader"  max-count='1' :before-read="beforeRead" :after-read='afterRead' />
                        </template>
                    </van-field>
                    <van-field required v-model="FormData.UI_UserName" label="用户名" placeholder="用户名"  :rules="[{ required: true, message: '用户名' }]" />
                    <van-field required v-model="FormData.UI_Name" label="姓名" placeholder="姓名"  :rules="[{ required: true, message: '姓名' }]" />
                    <van-field required disabled v-model="FormData.UI_Phone" maxlength="11" label="手机号" placeholder="手机号"  :rules="[{ required: true, message: '手机号' }]" />
                    <van-field required v-model="FormData.UI_Email" label="邮箱" placeholder="邮箱"  :rules="[{ required: true, message: '邮箱' }]" />
                    <van-field required value='********' type='password' disabled label="登录密码" placeholder="登录密码" >
                        <template #button>
                            <van-button class="ButtomBox" size="small" type="warning" native-type='button' @click="$router.push({path:'/user/updataPsswrod',query:{phone:FormData.UI_Phone}})">修改密码</van-button>
                        </template>
                    </van-field>
                </van-cell-group>
            </van-form>
        </div>
         
        <van-popup v-model="ShowImgCropper" :close-on-click-overlay='false' >
           <div class="CropperBox">
                <div class="BoxConter">
                    <img :src="ImgCropperSrc" alt="" ref="CropperRef">
                </div>
                <div class="CropperBtn">
                    <span @click="CroppClick">取消</span>
                    <span @click="OkCroppClick">确定</span>
                </div>
           </div>
        </van-popup>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="ClickInfo">修改信息</van-button>
        </div>
    </div>
</template>
<script>
import { NavBar,Form,CellGroup,Field,Button, Uploader ,Popup ,Icon  ,Dialog ,Toast} from 'vant';
import {Api_YyUserInfo_GetInfo,Api_YyUserInfo_AddEditInfo} from '@/api/userInfo'
import { Api_SysCode_UploadImg } from "@/api/utils";

export default {
    components:{
        [NavBar.name]: NavBar,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Uploader.name]: Uploader,
        [Popup.name]: Popup,
        [Icon.name]: Icon,
        [Toast.name]: Toast,
    },
    computed:{
        uploader:{
            get:function(){
                if(this.FormData.UI_HeadImg){
                    return [{url:this.FormData.UI_HeadImg}]
                }else {
                    return []
                }
                
            },
            set:function(newVal){
              
                if(newVal.length < 1) {
                    this.FormData.UI_HeadImg = ''
                }
            },
        }
    },
    data() {
        return {
           ShowImgCropper:false,
           ImgCropperSrc:'',
           cropper:'',
           FormData:{
                UI_UserName      :'',
                UI_HeadImg       :'',
                UI_Name          :'',
                UI_Phone         :'',
                UI_Email         :'',
                UI_PassWord      :'',
            } 
        }
    },
    created(){
        
        Api_YyUserInfo_GetInfo().then(res=>{
         
            this.FormData = res
        })
    },
    methods:{
        // 退出
        Colsed(){
            Dialog.confirm({
            title: '退出操作',
            message: '确定要退出系统操作？',
            }).then(() => {

                localStorage.clear()
                this.$router.push('/login')
                // on confirm
            }).catch(() => {
                // on cancel
            });
        },
        OkCroppClick(){     
               this.cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
             
                let files = new File([blob], new Date().getTime + '.png', {type: blob.type})
                formData.append('file',files);

                Api_SysCode_UploadImg(formData).then(res=>{
                    
                    this.FormData.UI_HeadImg= res[0].FilePath
                    this.CroppClick()
                }).catch(err=>{
                    this.$toast('操作失败')
                    
                    this.CroppClick()
                })
            
            });
                
        },
        // 取消文件裁剪
        CroppClick(){
            this.cropper.destroy()
            this.ShowImgCropper = false
            this.ImgCropperSrc = ''
        },

        initCropper () {
            

             this.cropper = new Cropper(this.$refs.CropperRef, {
                    toggleDragModeOnDblclick:false,
                    dragMode:'move',
                    toggleDragModeOnDblclick:false,
                    aspectRatio: 1,
                    viewMode: 1,
                    zoomOnWheel:false,//是否允许通过鼠标滚轮来缩放图片
                    background:true,//是否在容器上显示网格背景
                    rotatable:true,//是否允许旋转图片
                    // autoCrop:false,
                    cropBoxResizable:false,
                    ready:function(){
                        // this.cropper.crop()
                        this.cropper.setCropBoxData({
                            width:200,
                            height:200,
                        })  
                    },
                });
               
        },
        afterRead(file){
            this.ImgCropperSrc = URL.createObjectURL(file.file)
            this.ShowImgCropper = true
            this.$nextTick(()=>{
                this.initCropper()
            })
        },
        beforeRead(file) {
             const isLt2M = file.size / 1024 / 1024 < 5;
             if (!isLt2M) {
                 this.$toast('上传头像图片大小不能超过 5MB!')
               return false
            }
            return true;
        },
        ClickInfo(){
            this.$refs.form.submit()
        },
        onSubmit(){
            Api_YyUserInfo_AddEditInfo({
                UI_UserName:this.FormData.UI_UserName,
                UI_HeadImg:this.FormData.UI_HeadImg,
                UI_Email:this.FormData.UI_Email,
                UI_Name:this.FormData.UI_Name,
            }).then(res=>{
                // console.log(this)
                this.$toast('修改成功')

            })

        }
    }
}
</script>
<style lang="scss" scoped>
.userInfoBox {
    display: flex;
    flex-direction: column;
    // background-color: #fff;
    height: 100vh;

    .FormBox {
        flex: 1;
        padding-top: 10px;
        overflow-y: auto;
        /deep/ .van-field__button {
            overflow: hidden;
            display: flex;
        }
        .ButtomBox {
            height: 24px;
            line-height: 24px;
        }
    }
    .InfButtom {
        padding: 10px 14px;
        background-color: #fff;

        .van-button {
            line-height: 38px;
            height: 38px;
        }
    }
    .van-uploader {
        /deep/ .van-image  {
            border-radius: 50%;
        }
        /deep/.van-uploader__upload {
            border-radius: 50%;
            overflow: hidden;

        }

    }
    .van-popup {
        width: 100%;
        background: transparent;
    }
    .CropperBox {
        height: 100vh;
        display: flex;
        flex-direction:column;
    }
    .BoxConter {
        width: 100%;
        flex: 1;
        overflow: hidden;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .CropperBtn {
        display: flex;
        flex-direction: row;
        background: rgba(0,0,0,.5);
        span {
            flex: 1;
            font-size: 15px;
            color: #fff;
            letter-spacing: 1px;
            padding: 10px 12px;
        }
        span:last-child {
            text-align:right;
        }
    }
    .CloseBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        i {
            color: #1989fa;
        }
        em {
            font-size: 15px;
            color: #1989fa;
            margin-left: 3px;
        }
    }
}
</style>